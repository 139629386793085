import React, { RefObject } from "react";

import { Form, Formik, FormikProps } from "formik";
import { t } from "i18next";

import {
	CommercialPropositionFormSection,
	ICommercialPropositionFormSection,
} from "./CommercialPropositionFormSections";
import {
	CommercialPropositionFormInitialValues,
	CommercialPropositionFormValidation,
	ICommercialPropositionFormValues,
} from "./CommercialPropositionFormValidation";
import {
	Button,
	InfoMessage,
	PopoverCursor,
	Title,
} from "@zolteam/react-ras-library";
import { BlockNavigation, SubmitCPFormModal } from "components/organisms";
import { treatErrors } from "utils/forms";

import { filterPJForConceptRecrutment } from "utils/commercialPropositions";

import { CP_STATUS_SENT } from "constants_globals";

import "./CommercialPropositionForm.scss";

export interface ICommercialPropositionFormSectionProps {
	locked: boolean;
	commercialPropositionStatus: string;
	cpType?: string;
}

interface ICommercialPropositionFormProps {
	type?: string;
	onSave?: (
		values: ICommercialPropositionFormValues,
		formikHelpers: any
	) => Promise<any>;
	onSubmit: (
		values: ICommercialPropositionFormValues,
		formikHelpers: any
	) => Promise<any>;
	innerRef?:
		| (RefObject<FormikProps<ICommercialPropositionFormValues>> &
				RefObject<HTMLFormElement>)
		| undefined;
	initialValues?: ICommercialPropositionFormValues;
	isLocked?: boolean;
}

export const CommercialPropositionForm: React.FC<
	ICommercialPropositionFormProps
> = ({ onSubmit, onSave, type, innerRef, initialValues, isLocked = false }) => {
	const [commercialPropositionStatus] = React.useState("");
	const [IsSubmitModalOpen, setIsSubmitModalOpen] = React.useState(false);
	const submitType = React.useRef<any>(null);

	return (
		<Formik
			initialValues={{
				...(CommercialPropositionFormInitialValues as any),
				...initialValues,
				cpType: type,
			}}
			onSubmit={(values, helpers) => {
				if (submitType.current === "save" && onSave)
					return onSave(values, helpers).then(() => {
						helpers.resetForm({ values });
					});
				return onSubmit(values, helpers).then(() => {
					helpers.resetForm({ values });
				});
			}}
			validationSchema={CommercialPropositionFormValidation()}
			innerRef={innerRef}
		>
			{({
				isValid,
				dirty,
				isSubmitting,
				values,
				setFieldValue,
				setFieldTouched,
				setSubmitting,
				validateForm,
				resetForm,
			}) => {
				const resetDefaultPjValues = () => {
					let files =
						values.commercialPropositionPjs?.filter((a) => {
							if (
								values.defaultPjs?.find(
									(b) => b.pjName === a.pjName
								)
							)
								return false;
							return true;
						}) ?? [];

					files?.unshift(...(values.defaultPjs ?? []));

					files = filterPJForConceptRecrutment(files, values.agency);

					setFieldValue("commercialPropositionPjs", [...files]);
				};

				if (values?.cpType !== type) setFieldValue("cpType", type);

				return (
					<Form className="w-full">
						<div className="[&>div:not(:last-child)]:border-b [&>div:not(:first-child)]:pt-6 w-full dark:[&>div:not(:last-child)]:border-neutral-700">
							{CommercialPropositionFormSection.map(
								(section, index) => {
									return (
										<div key={index}>
											<FormSection
												item={section}
												index={index + 1}
												props={{
													locked:
														isSubmitting ||
														isLocked,
													commercialPropositionStatus,
													cpType: type,
												}}
											/>
										</div>
									);
								}
							)}
						</div>
						<div className="sticky bottom-0 z-20 items-center justify-end w-full gap-8 py-4 border-t bg-neutral-50 dark:bg-neutral-800 dark:border-neutral-900 row border-neutral-200">
							<PopoverCursor
								content={isLocked && <LockedPopover />}
								followCursor={false}
								placement="top"
							>
								<span>
									<Button
										type="button"
										disabled={isLocked || isSubmitting}
										color="transparent"
										isLoading={isSubmitting}
										onClick={() => {
											submitType.current = "save";
											setSubmitting(true);
											onSave
												? onSave(values, {})
														.then(() => {
															resetForm({
																values,
															});
														})
														.finally(() => {
															setSubmitting(
																false
															);
														})
												: setSubmitting(false);
										}}
									>
										{t("commercialPropositions.form.save")}
									</Button>
								</span>
							</PopoverCursor>
							<PopoverCursor
								content={isLocked && <LockedPopover />}
								followCursor={false}
								placement="top"
							>
								<span>
									<Button
										type="button"
										disabled={isLocked || isSubmitting}
										color="primary"
										isLoading={isSubmitting}
										onClick={() => {
											resetDefaultPjValues();
											validateForm().then((errors) => {
												const hasErrors =
													Object.keys(errors)
														?.length > 0;
												if (!hasErrors && isValid) {
													resetDefaultPjValues();
													submitType.current =
														"submit";

													setIsSubmitModalOpen(true);
												} else {
													setTimeout(() => {
														treatErrors(
															errors,
															setFieldTouched
														);
													}, 100);
												}
												return Promise.resolve(true);
											});
										}}
									>
										{values.status === CP_STATUS_SENT
											? t(
													"commercialPropositions.form.reSend"
											  )
											: t(
													"commercialPropositions.form.send"
											  )}
									</Button>
								</span>
							</PopoverCursor>
						</div>
						<SubmitCPFormModal
							isDisplayed={IsSubmitModalOpen}
							onClose={() => {
								setIsSubmitModalOpen(false);
							}}
						/>
						<BlockNavigation
							shouldPrompt={(dirty || isSubmitting) && !isLocked}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

const LockedPopover: React.FC = () => (
	<div className="p-2 pt-0 bg-white shadow-lg rounded-xl">
		<InfoMessage color="primary" withIcon>
			{t("commercialPropositions.lockedBecauseSigned")}
		</InfoMessage>
	</div>
);

interface IFormSectionProps {
	item: ICommercialPropositionFormSection;
	index: number;
	props: ICommercialPropositionFormSectionProps;
}

const FormSection: React.FC<IFormSectionProps> = ({ item, index, props }) => {
	return (
		<div className="pb-8 max-w-[700px]" data-scroll-to={`section-${index}`}>
			<Title
				tag="h2"
				size="heading02"
				className="sticky -top-[32px] z-10 mb-4 bg-neutral-50 dark:bg-neutral-800 dark:text-white w-full"
			>
				{item.title}
			</Title>
			<item.component {...props} />
		</div>
	);
};
