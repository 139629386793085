import React from "react";

import { t } from "i18next";
import { TAgency } from "types/TAgency";

import { useQuery } from "@tanstack/react-query";

import AgencyService from "services/AgencyService";
import { IQuery } from "services/api";

import { Select, InfoMessage } from "@zolteam/react-ras-library";

interface IAgencySelectProps {
	onChange?: (value: TAgency) => void;
	onFetched?: (agencies: TAgency[]) => void;
	query?: IQuery;
	[key: string]: any; // generic props because zolteam library doesn't have types
}

export const AgencySelect: React.FC<IAgencySelectProps> = ({
	name = "agency",
	query,
	required = false,
	...props
}) => {
	const loadOptions = (args: any) =>
		(query?.fn ?? AgencyService.getPublicAgencies)(args)
			.then((resp: TAgency[]) => {
				return resp.sort((a, b) => a.name.localeCompare(b.name));
			})
			.then((resp: TAgency[]) => {
				props.onFetched?.(resp);
				return resp;
			});

	const {
		isLoading,
		data: agencies,
		error,
	} = useQuery({
		queryKey: query?.key ?? ["agencies", "public"],
		queryFn: loadOptions,
	});

	return (
		<div className="w-full [&_p:empty]:hidden">
			<Select
				className="[&>*>input]:placeholder:text-sm"
				name={name}
				label={t("forms.agencies.label")}
				placeholder={t("forms.agencies.placeholder")}
				options={agencies}
				noOptionsMessage={({ inputValue }: { inputValue: string }) =>
					!inputValue?.length
						? t("forms.agencies.placeholder")
						: t("forms.search.noResults", {
								search: inputValue,
						  })
				}
				getOptionLabel={(option: TAgency) => option.name}
				getOptionValue={(option: TAgency) => option.id}
				isLoading={isLoading}
				{...props}
				value={
					props.value &&
					typeof props.value !== "object" &&
					agencies?.length
						? agencies.find(
								(agency: TAgency) => agency.id === props.value
						  )
						: props.value
				}
			/>

			{!!error && (
				<div className="[&>*]:w-full">
					<InfoMessage color="error" withIcon>
						{t("forms.agencies.loadError")}
					</InfoMessage>
				</div>
			)}
			{required && (
				<span className="ml-6 text-xs italic text-neutral-400">
					{t("forms.field.mandatory")}
				</span>
			)}
		</div>
	);
};
